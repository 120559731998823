<template>
  <!-- HEADER -->
  <section>
    <div class="topBar-mobile">

      <div class="container">

        <div class="row">

          <div class="col-md-6 col-sm-6 col-xs-6">

            <div class="navbar-header">
              <router-link class="navbar-brand" :to="{ name: 'home' }">
                <img class="img-responsive" :src="'/eurasia/img/logo/logo.png'" alt="logo">
              </router-link>

            </div>

          </div>

          <div class="col-md-6  col-sm-6 col-xs-6 text-right">

            <ul class="list-inline pull-right top-right hover topbar-icon-mobile d-none d-md-block"
              style="padding-top: 13px;">

              <li><i class="fas fa-truck fa-3x "></i></li>

              <li><i class="fa fa-gift fa-3x"></i></li>

              <li v-if="!accessToken">
                <router-link :to="{ name: 'login' }">Login</router-link>
              </li>
              <li v-if="accessToken">
                <router-link :to="{ name: 'customer_dashboard' }">{{ customer.customer.name }}</router-link>
              </li>
              <li v-if="accessToken" @click="logout" style="cursor: pointer"><a>Logout</a></li>
              <li v-if="!accessToken">
                <router-link :to="{ name: 'registration' }">Register</router-link>
              </li>
            </ul>

            <button class="mobile-button" @click="openMbNav()">

              <i class="fa fa-bars"></i>

            </button>

          </div>

        </div>

      </div>

    </div>
    <div class="mobile-sreach-bar">

      <div class="container">

        <div class="row">

          <div class="col-md-1 col-sm-1 col-xs-2 d-none d-md-block">

            <button class="mobile-button" @click="openMbNav()">

              <i class="fa fa-bars"></i>

            </button>

          </div>

          <div class="col-md-8 col-sm-8 col-xs-12 ">

            <form @submit.prevent="goToSearchPage(productSearch)" name="frm_sitesearch_form" accept-charset="utf-8"
              method="get">
              <div class="input-group">
                <input type="text" name="q" v-model="productSearch" value="searchQuery"
                  @input="e => productSearch = e.target.value" id="search_product_auto_mobile"
                  class="form-control input_search1 ui-autocomplete-input" placeholder="Search">

                <div class="input-group-btn">

                  <button class="btn btn-default sreach-button" type="submit">

                    <i class="fas fa-search srch-icon"></i>

                  </button>

                </div>

              </div>

            </form>

          </div>

          <div class="col-md-3 col-sm-3 col-xs-3 d-none d-md-block" style="overflow: hidden">

            <a href="#" data-toggle="modal" data-target="#myModal3" class="mobile-cart pull-left">

              <i class="fa fa-shopping-cart"></i>

              <span id="mobile_cart_view">{{ shoppingBag.length }} Item</span>

            </a>

          </div>

        </div>
        <!--            Search Result for mobile-->
        <div v-if="searchItem" class="search-item">
          <ul v-if="productBySearch.length > 0">
            <li v-for="product in productBySearch" :key="product.id">
              <router-link :to="{ name: 'product', params: { slug: product.slug, type: product.product_type } }">
                {{ product.name }}
              </router-link>
            </li>
          </ul>
        </div>
        <!--            Search Result for mobile-->

      </div>

      <!--            MobileMenu          -->
      <div :class="{ 'active-mobile-menu': mainMobileNav }" class="mobile-nav-area">
        <ul class="navigation m-nav">
          <div class="subNav m-main-menu-title">
            <h2>Main Menu</h2>
            <span class="close-m-nav" @click="hideMainNav"><i class="fa fa-remove"></i></span>
          </div>
          <li class="main-li" v-for="(item, index) in navigation" :key="'item' + index">
            <i v-if="item.sub_category.length > 0" class="icon" :class="{
              'fa fa-angle-right': item.open,
              'ion-md-remove': !item.open,
            }" @click="showSubMenu(item, index)" />
            <router-link tag="a" class="nav-link" exact :to="item.slug === 'home'
              ? '/'
              : { name: 'category', params: { slug: item.slug } }
              ">{{ item.name }}
            </router-link>
          </li>
          <li>
            <a @click="openBrand">Brand</a>
          </li>
        </ul>
        <ul v-show="isSubNav" class="sub_nav m-nav">
          <div class="subNav" @click="hideSubNave">
            <span><i class="fa fa-angle-left"></i></span>Back
          </div>
          <li class="main-li" v-for="(item, index) in subNav.sub_category" :key="'item' + index">
            <i v-if="item.sub_category.length > 0" class="icon" :class="{
              'fa fa-angle-right': !item.open,
              'ion-md-remove': item.open,
            }" @click="showSubSubMenu(item, index)" />
            <router-link tag="a" exact :to="{ name: 'category', params: { slug: item.slug } }">{{ item.name }}
            </router-link>
          </li>
        </ul>
        <ul v-show="isSubSubNav" class="sub_sub_nav m-nav">
          <div class="subNav" @click="hideSubSubNave">
            <span><i class="fa fa-angle-left"></i></span>Back
          </div>
          <li class="main-li" v-for="(item, index) in subSubNav.sub_category" :key="'item' + index">
            <router-link tag="a" exact :to="{ name: 'category', params: { slug: item.slug } }">
              {{ item.name }}
            </router-link>
          </li>
        </ul>
        <ul v-show="brandMenu" class="sub_sub_nav m-nav brand-nav">
          <div class="subNav" @click="brandMenu = false">
            <span><i class="fa fa-angle-left"></i></span>Back
          </div>
          <li style="padding-left: 15px">
            <input type="text" class="form-control" placeholder="Enter brand" v-model="brandSearch">
          </li>
          <li v-for="spcbrand in brandsByTextFilter" :key="spcbrand.id">
            <router-link class="main-title-sub-nav" :to="{ name: 'brand', params: { slug: spcbrand.slug }, }">{{
              spcbrand.name }}</router-link>
          </li>
        </ul>
      </div>
      <!--            MobileMenu          -->
      <!---------------------------------------------Cart list modal------------------------------------------------------------------->
      <!--    Sidebar Cart-->
      <div data-toggle="modal" data-target="#myModal3" class="right-fixed-cart d-none d-md-block">
        <i class="fa fa-shopping-cart"></i>
        <p class="right-fixed-cart-total">৳{{ total }}</p>
      </div>
      <!-- Modal -->
      <div class="modal right fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="z-index: 99;">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">&times;</span>
              </button>

              <!--h4 class="modal-title" id="myModalLabel2">Right Sidebar</h4-->


            </div>
            <div class="modal-body" id="product_listing">
              <!--h3 class="pageHead" style="margin-top:0px;"></h3-->
              <div class="new_fullpage_box" style="margin-bottom:25px;"><!-- begin:page_Inner -->
                <form name="frm_mycart_form" method="post" action="https://eurasiasupplies.com/products/updatecart/">
                  <div class="row">
                    <div id="no-more-tables" class="table_overflow">
                      <table class="table-bordered table-striped table-condensed cf" border="0" cellspacing="1"
                        cellpadding="8">
                        <thead class="cf">
                          <tr class="tableHead" bgcolor="#d1d6dc">

                            <td class="black12bold paddingleft10" align="center" width="15%" height="30">
                              Picture
                            </td>

                            <td class="black12bold numeric" align="left" width="35%">Product
                              Name
                            </td>
                            <td class="black12bold numeric" width="2%" align="center">QTY</td>

                            <td class="black12bold numeric" width="8%" align="center">Price</td>

                            <td class="black12bold numeric" width="2%" align="center"></td>
                          </tr>

                        </thead>

                        <tr v-if="shoppingBag.length === 0">
                          <td colspan="6" class="black12bold numeric">
                            <p style="margin-bottom:5px;">There is no product into your
                              cart.</p>
                          </td>
                        </tr>
                        <tr v-if="shoppingBag.length > 0" v-for="bag in shoppingBag">
                          <!--                          <pre>{{bag}}</pre>-->
                          <td class="black12bold numeric">
                            <img style="height: 40px; margin: 5px 0px"
                              :src="'https://eurasiasupplies.com/euadmin/' + bag.cartImage" alt="">
                          </td>
                          <td class="black12bold numeric">
                            {{ bag.name }}
                          </td>
                          <td class="black12bold numeric">
                            <div class="qty-in-dec" style="display: inline-flex">
                              <button type="button" class="minus-cart" @click="deductQty(bag)">
                                <i class="fa fa-minus"></i>
                              </button>
                              <div style="margin: 0px 2px">{{ bag.qty }}x{{ bag.amount / bag.qty }}</div>
                              <button :disabled="bag.product_price[0].quantity === $store.getters.prodQty(bag.id)"
                                type="button" class="plus-cart" @click="addQty(bag)">
                                <i class="fa fa-plus"></i>
                              </button>
                            </div>

                            <!--                            {{ bag.qty }}x{{ bag.unitPrice }}-->
                          </td>
                          <td class="black12bold numeric">
                            {{ bag.amount }}
                          </td>

                        </tr>
                      </table>
                    </div>

                    <table width="100%">
                      <tr>
                        <td class="numeric" colspan="6" align="right">
                          <div style=" height:5px;"></div>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="Subtotal" class="numeric" colspan="6" align="right">
                          <span class="black12bold" style="float:none;">Sub Total:&nbsp;&nbsp;&nbsp;&nbsp;BDT {{ total
                            }}.00</span>
                        </td>
                        <td class="black12normal dottedborder padding5 numeric" width="10%">
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td class="numeric" colspan="6" align="right" id="shippingvalue">
                        </td>
                        <td class="black12normal dottedborder padding5 numeric" width="10%">
                          &nbsp;
                        </td>
                      </tr>


                      <tr>
                        <td class="numeric" colspan="5">


                          <!--<button class="btn btn-sm btn-primary btn_font" class="close"
                                  data-dismiss="modal" aria-label="Close">Continue Shopping
                          </button>-->


                          <!--input type="button" class="btn btn-sm btn-primary btn_font" value=" Go to Homepage " onclick="javascript:window.location='home/';" /-->


                        </td>
                        <td class="numeric" align="center" valign="middle" colspan="2">
                          <input type="button" class="btn btn-sm btn-primary btn_font" @click="goTOCheckOut"
                            value=" Check Out" />
                        </td>
                      </tr>
                    </table>
                  </div>
                </form>
              </div>
              <div class="clr"></div>
            </div>


          </div><!-- modal-content -->

        </div><!-- modal-dialog -->

      </div><!-- modal -->

      <div>
        <div class="bottom-fixed-menu">
          <div class="mobile-bottom-menu"
            style="display: flex; justify-content: space-around; align-items: center; height: 50px;">
            <span>
              <router-link class="mobile-menu" tag="a" exact :to="{ name: 'home' }">
                <i class="fas fa-home home-icon"></i>
              </router-link>
            </span>

            <span>
              <!-- <button class="mobile-button" @click="openMbNav()">
                <i class="fas fa fa-file-text" aria-hidden="true"></i>
              </button> -->
              <!-- <img src="../../../public/eurasia/img/document.svg" width="24px" alt=""> -->

              <router-link class="mobile-menu"  :to="{ name: 'pages', params: { slug: 'pages%2F4-terms-condition' }, }">
                <i class="fas fa fa-file-text"></i>
              </router-link>

              <!-- <a style="text-decoration: none; color: white;" href="page/pages%2F4-terms-condition">
                <i class="fas fa fa-file-text"></i>
              </a> -->

              <!-- <span>Test</span> -->
            </span>

            <span data-toggle="modal" data-target="#myModal3">
              <span style="position: relative;">
                <i class="fas fa fa-cart-plus" aria-hidden="true"></i>
                <span v-if="shoppingBag.length > 0"
                  style="position: absolute; right: -15px; background: red; padding: 2px;border-radius: 100%; top: -12px; font-weight: bold; font-size: 14px;">
                  {{ shoppingBag.length }}
                </span>
              </span>
            </span>
            <!-- <span  data-toggle="modal" data-target="#myModal3" >
              <span>
                <i class="fas fa fa-cart-plus" aria-hidden="true"></i>
              </span>
            </span> -->
            <span v-if="!accessToken">
              <router-link class="mobile-menu" tag="a" exact :to="{ name: 'login' }">
                <i class="fas fa fa-user-circle" aria-hidden="true"></i>
              </router-link>
            </span>
            <span v-else>
              <router-link class="mobile-menu" tag="a" exact :to="{ name: 'customer_dashboard' }">
                <i class="fas fa fa-user-circle" aria-hidden="true"></i>
              </router-link>
            </span>
          </div>
        </div>
      </div>
      <!---------------------------------------------Cart list modal------------------------------------------------------------------->
    </div>
  </section>

</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: 'BrandMenu',
  props: {
    msg: String
  },
  data() {
    return {
      productSearch: '',
      isActiveMobileMenu: false,
      isOpen: false,
      navigation: [],
      isActive: true,
      subNav: [],
      subSubNav: [],
      isSubNav: false,
      isSubSubNav: false,
      mainMobileNav: false,
      brandMenu: false,
      brandSearch: "",
      searchItem: false
    };
  },
  computed: {
    ...mapGetters([
      "shoppingBag", "total", "accessToken", "customer", "categories", "productBySearch", "brands", "brandsByTextFilter"
    ]),
  },
  mounted() {
    this.$store.dispatch('getProductBySearchClear', 0);
    this.$store.dispatch("getCategories");
    this.productSearch = '';
    // console.log("Cate ====>", this.categories);
    setTimeout(() => {
      this.categories.forEach((item) => {
        item.open = true;
        item.sub_category.forEach((subItem) => {
          subItem.open = false;
          subItem.sub_category = subItem.sub_sub_category;
        });
        this.navigation.push(item);
        //// console.log("navigation =", this.navigation);
      });
    }, 100);
  },
  watch: {
    productSearch(val) {
      if (val.length > 2) {
        this.searchItem = true;
        this.$store.dispatch('getProductBySearch', val);
      } else if (val.length < 3) {
        this.searchItem = false;
        this.$store.dispatch('getProductBySearchClear', val);
      }
    },
    brandSearch(val) {
      this.$store.dispatch("getBrandByTextFiltering", val);
    },
    $route(newVlue, oldValue) {
      if (newVlue.fullPath !== oldValue.fullPath) {
        this.$store.dispatch('getProductBySearchClear', 0);
        this.productSearch = '';
        this.searchItem = false;
      }
      this.isSubNav = false;
      this.isSubSubNav = false;
      this.mainMobileNav = false;
    }
  },
  methods: {
    deductQty(product) {
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch("deductQty", product);
      }
      if (this.$store.getters.prodQty(product.id) == 0) {
        this.$store.dispatch("removeFromCart", product);
      }
      // if (product.qty <= product.product_price[0].quantity) {
      //   if (
      //       product.qty >= product.product_price[0].tier_quantity_three &&
      //       product.product_price[0].tier_quantity_three !== 0
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_three;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_two &&
      //       product.qty < product.product_price[0].tier_quantity_three
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_two;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_one &&
      //       product.qty < product.product_price[0].tier_price_two
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_one;
      //   } else if (product.product_price[0].offer_price) {
      //     this.proPrice = product.product_price[0].offer_price;
      //   } else {
      //     this.proPrice = product.product_price[0].price;
      //   }
      // }
    },
    addQty(product) {
      // // console.log('update', product.product_price[0].tier_quantity_three)
      this.$store.dispatch("addQty", product);
      // if (product.qty <= product.product_price[0].quantity) {
      //   if (
      //       product.qty >= product.product_price[0].tier_quantity_three &&
      //       product.product_price[0].tier_quantity_three !== 0
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_three;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_two &&
      //       product.qty < product.product_price[0].tier_quantity_three
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_two;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_one &&
      //       product.qty < product.product_price[0].tier_price_two
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_one;
      //   } else if (product.product_price[0].offer_price) {
      //     this.proPrice = product.product_price[0].offer_price;
      //   } else {
      //     this.proPrice = product.product_price[0].price;
      //   }
      // }
    },
    logout() {
      this.$store.dispatch("logout");
      //window.localStorage.clear();
      this.$router.push({ name: 'home' }).catch(() => {
      });
      location.reload();
    },
    goToSearchPage(searchVal) {
      if (searchVal) {
        this.$router.push('/search/' + searchVal).catch(() => {
        })
      }

    },
    goTOCheckOut() {
      //const el = document.querySelector('#bodyWrap');
      //el.classList.remove("active");
      this.$store.dispatch('hideCart');
      this.$router.push('/checkout').catch(() => {
      })
      // if (this.accessToken) {
      //   this.$router.push('/checkout').catch(() => {
      //   })
      // } else {
      //   this.$router.push({name: 'login'}).catch(() => {
      //   })
      // }

    },
    openMbNav() {
      this.mainMobileNav = true;
      this.$store.dispatch("sohwMobileMenu");
    },
    showSubMenu(item) {
      this.subNav = item;
      this.isSubNav = true;
    },
    showSubSubMenu(item) {
      this.subSubNav = item;
      this.isSubSubNav = true;
      // console.log("iiii", item);
    },
    hideMainNav() {
      this.mainMobileNav = false;
    },
    hideSubNave() {
      this.isSubNav = false;
    },
    hideSubSubNave() {
      this.isSubSubNav = false;
    },
    openBrand() {
      // console.log("Brand")
      this.brandMenu = true
      this.hideSubNave()
      this.hideSubSubNave()
    },
    capturePress: function (data) {
      // console.log(data);
    }

  },
}
</script>
<style>
.right-fixed-cart a {
  color: #fff;
  text-align: center;
}

p.right-fixed-cart-total {
  margin: 0;
  font-size: 15px;
  padding: 5px 0px;
  color: #fff;
  font-weight: bold;
}

.right-fixed-cart {
  position: fixed;
  color: #fff;
  right: 0;
  top: 48vh;
  background: red;
  font-size: 20px;
  padding: 20px 7px;
  text-align: center;
  border-radius: 15px 0px 0px 15px;
  cursor: pointer;
}

.bottom-fixed-menu {
  position: fixed;
  color: #fff;
  bottom: 0;
  background: #FF0000;
  font-size: 20px;
  /* padding: 20px 7px; */
  text-align: center;
  /* border-radius: 15px 0px 0px 15px; */
  cursor: pointer;
  width: 100%;
  height: 50px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  z-index: 999;

}

.activeMobileMenu {
  width: 100% !important;
}

.inactiveMobileMenu {
  width: 0% !important;
}

.search-item {
  height: 300px;
  text-align: left;
  background: #fff;
  overflow-y: auto;
  position: absolute;
  z-index: 1;
  width: 97%;
}

.search-item ul {
  padding: 5px;
}

.search-item ul li a {
  color: #000;
}

.search-item ul li a:hover {
  color: #fff;
  background: #000
}


/*----- Mobile Menu ------------*/
.subNav.m-main-menu-title {
  position: relative;
}

.subNav.m-main-menu-title h2 {
  margin: 0;
  padding: 13px 0px;
  text-align: left;
  color: #fff;
  font-size: 13px;
}

span.close-m-nav {
  position: absolute;
  top: 0;
  height: 60px;
  width: 40px;
  background: #e97e7e;
  right: -40px;
}

span.close-m-nav .fa {
  padding: 29px 0px;
  color: #fff;
}

.subNav .fa-angle-left {
  color: #fff;
  padding: 20px 0px 20px 0px;
  width: 22px;
}

.mobile-nav-area {
  display: none;
}

.brand-nav {
  height: 100vh;
  overflow: scroll;
}

.mobile-bottom-menu span i {
  font-size: 24px;
  cursor: pointer;
}

.mobile-menu {
  color: #fff;
}

@media (max-width: 766px) {
  .mobile-nav-area {
    display: block;
    position: absolute;
    z-index: 99;
    top: 0;
    left: -800px;
    width: 100%;
    background: #000000c7;
    transition-duration: 1s;
    height: 100vh;
  }

  .active-mobile-menu {
    transition-duration: 1s;
    z-index: 99;
    left: 0;
    height: 100vh;
    position: fixed;
    overflow: scroll;
  }

  .m-nav {
    list-style: none;
    border-bottom: 1px solid #dedede;
    width: 80%;
    height: 100vh;
    background-color: #fff;
    /*background: rgb(166, 61, 61);
            background: linear-gradient(180deg, rgba(166, 61, 61, 1) 0%, rgba(101, 33, 46, 1) 35%, rgba(5, 10, 70, 1) 100%);*/
  }

  .m-nav li {
    position: relative;
    color: #000;
    border-top: 1px solid rgb(255 179 183 / 25%);
    cursor: pointer;
    font-weight: 300;
    text-transform: capitalize;
  }

  ul.navigation.m-nav li a {
    color: #010101;
    padding: 11px;
    display: block;
    text-align: left;
  }

  ul.navigation.m-nav li a:hover {
    background-color: red;
  }

  .active-mobile-menu .router-link-active {
    background-color: red;
    color: #fff !important;
  }

  .navigation .title {
    padding: 10px 0;
    text-indent: 20px;
  }

  .m-nav i {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 35px;
    text-align: center;
    color: #817c7c;
    font-size: 20px;
    line-height: 0;
    background: #d5d5d5;
    padding: 20px 0px;
  }

  /*second*/
  .sub_nav {
    position: absolute;
    top: 0;
  }

  .sub_nav li a {
    padding: 11px;
    display: block;
    color: #010101;
    text-align: left;
  }

  /*----*/
  .sub_sub_nav {
    position: absolute;
    top: 0;
  }

  .sub_sub_nav li a {
    padding: 11px 11px;
    display: block;
    color: #010101;
    text-align: left;
  }

  .subNav {
    /*background: #943539;*/
    background: red;
    font-weight: 500;
    color: #fff;
    padding: 9px 10px;
    letter-spacing: 1px;
    cursor: pointer;
    border-bottom: 2px solid #fd0e15;
    text-align: right;
  }

  .modal.right.fade.in .modal-dialog {
    right: 10px;
    width: 95%;
  }

  .subNav i {
    position: unset !important;
    background: transparent !important;
    padding-right: 6px;
    font-size: 16px !important;
  }

  /*mobile menu css End here*/
  #mobile_only {
    display: block !important;
    background: #fff;
  }

  #desktop_only {
    display: none !important;
  }

  .search-item {
    height: 300px;
    text-align: left;
    background: #fff;
    overflow-y: auto;
    position: absolute;
    z-index: 99;
    width: 95%;
    left: 2%;
    border-radius: 3px;
  }

  .search-item li a {
    display: block;
    padding: 8px 5px;
    border-bottom: 1px solid #ddd;
  }
}
</style>